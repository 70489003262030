import React, {useState, useEffect, FunctionComponent} from 'react';
import axios from 'axios';

const App : FunctionComponent<{}> = () => {
  const [state, set_state] = useState<null|'logged in'>(null);

  useEffect(() => {
    (async() => {
      axios.defaults.withCredentials = true;

      const response = await axios.post('/api', {type: 'get_authenticated_user_info'});

      if(response.data.payload.value === false) {
        window.location.replace('/login');
      } else {
        set_state('logged in');
      }
    })();
    return;
  }, []);

  if(state === null) {
    return (
      <>Loading ...</>
    );
  } else {
    return (
      <>This app is not fully built yet. <a href="/logout">Log out</a></>
    );
  }
};

export default App;
